import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// Logos
import WideOrbit from "assets/svg/wideorbit.inline.svg";
import broadviewSoftware from "assets/img/logos/BV_software_logo_c-1024x373.png";
import dashbox from "assets/img/logos/dashbox-logo.png";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";
import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";

import { grayColor, title } from "assets/jss/material-kit-pro-react.js";

import bg7 from "assets/img/broadcast.jpg";

const useStyles = makeStyles(featuresStyle);
const useStyles3 = makeStyles(teamsStyle);
const useStyles2 = makeStyles({
  infoAreaImageArea: {
    maxWidth: "360px",
    margin: "0 auto",
    padding: "70px 0 30px",
  },
  imageWrapper: {
    marginTop: "24px",
    marginRight: "10px",
  },
  descriptionWrapper: {
    color: grayColor[0],
    overflow: "hidden",
  },
  description: {
    color: grayColor[0],
    overflow: "hidden",
    marginTop: "0px",
    "& p": {
      color: grayColor[0],
      fontSize: "14px",
    },
  },
  title: {
    ...title,
    margin: "1.75rem 0 0.875rem !important",
    minHeight: "unset",
  },
});

export default function SectionFeatures({ ...rest }) {
  const classes = useStyles();
  const classes2 = useStyles2();
  const classes3 = useStyles3();
  return (
    <>
      <div className="cd-section" {...rest}>
        <div
          className={classes3.team + " " + classes3.section}
          style={{ backgroundImage: `url(${bg7})` }}
        >
          <div className={classes3.container}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={classes3.mlAuto + " " + classes3.mrAuto}
              >
                <h2 className={classes3.title}>Industry Partners</h2>
                <h5 className={classes3.description} style={{ color: "#fff" }}>
                  We have forged relationships with some of the biggest names in
                  the industry in order that your systems can integrate
                  seamlessly with Trace and ON-AIR Pro.
                </h5>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <div className="cd-section" {...rest}>
        <div className={classes.container}>
          <div className={classes.features1}>
            <GridContainer alignItems="center">
              <GridItem xs={12} sm={4} md={4}>
                <div className={classes2.infoAreaImageArea}>
                  <div className={classes2.imageWrapper}>
                    <WideOrbit width="60%" />
                  </div>
                  <div className={classes2.descriptionWrapper}>
                    <div className={classes2.description}>
                      In use at over 4,000 stations and networks globally, and
                      managing more than $14 billion in advertising revenue,
                      WideOrbit is a premier traffic system for the broadcast
                      industry. ON-AIR Pro™ can feed the WideOrbit system by
                      sending it finished promo information.
                    </div>
                  </div>
                </div>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <div className={classes2.infoAreaImageArea}>
                  <div className={classes2.imageWrapper}>
                    <img
                      src={broadviewSoftware}
                      alt="Broadview Software logo"
                      width="60%"
                    />
                  </div>
                  <div className={classes2.descriptionWrapper}>
                    <div className={classes2.description}>
                      Similar to ON-AIR Pro™, BroadView outfits cable networks
                      with an integrated system combining program acquisitions,
                      scheduling, traffic, ad management, reporting, and
                      automation management. Integration between ON-AIR Pro™ and
                      BroadView’s program schedules gives ON-AIR Pro™ users the
                      benefit of realtime program schedule changes.
                      Additionally, ON-AIR Pro’s™ air planning and work ordering
                      system provides Broadview users access to a complete and
                      up-to-date promo inventory.
                    </div>
                  </div>
                </div>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <div className={classes2.infoAreaImageArea}>
                  <div className={classes2.imageWrapper}>
                    <img src={dashbox} alt="Dashbox logo" width="60%" />
                  </div>
                  <div className={classes2.descriptionWrapper}>
                    <div className={classes2.description}>
                      Dashbox is a cloud-based music asset and metadata
                      management service that sources, updates, hosts and
                      manages all of your licensed library music and SFX so you
                      and your team can focus on making great content. ON-AIR
                      Pro integrates with Dashbox to ensure the consistency and
                      accuracy of music cues and metadata across your projects.
                    </div>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </>
  );
}
