import * as React from "react";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle";
import "typeface-roboto";
import "typeface-roboto-slab";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import MyHeader from "components/Header/MyHeader";
import MyFooter from "components/Footer/MyFooter";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Parallax from "components/Parallax/Parallax";

// Sections for this page
import SectionContacts from "./HomePage/Sections/SectionContacts";
import SectionFeatures from "./AboutPage/Sections/SectionFeatures";

const useStyles = makeStyles(aboutUsStyle);

// markup
const StoryPage = ({ ...rest }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();

  return (
    <div>
      <MyHeader {...rest} />
      <Parallax
        image={require("assets/img/motion_pictures.jpg")}
        filter="dark"
        small
      ></Parallax>
      <div className={classNames(classes.main)}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}></GridItem>
            <GridItem
              xs={12}
              sm={7}
              md={7}
              className={`${classes.mlAuto} ${classes.mrAuto}`}
            >
              <h2 className={classes.title} style={{ color: "black" }}>
                Company Story
              </h2>
              <h4 style={{ color: "black" }}>
                Every tool comes about because there is need or a problem to
                solve, a task to accomplish.
              </h4>
              <br />
              <p>So it is with us.</p>
              <p>
                Thought Development started as a training organization. We
                taught the use of technology for people in the entertainment
                business, usually within a team context. People at that time
                (and even now) were trying to solve extreme tasking problems
                with Word and Excel and email. It was all that they had and were
                familiar with. So they hired us to “teach them” to become more
                productive with these tools to dig into some very large on going
                daily challenges.
              </p>
              <p>
                It might sound silly now, but on more than one occasion while
                trying to consult in MS Office it was stated, “You know, for
                what you are trying to do, you need a database…” with the
                resulting response by the client, “What is a database and how
                can that help me?!”
              </p>
              <p>
                In the explanation they would just stop us mid sentence and say
                “Can you just do that for us.” And so we followed through with
                learning and building everything they needed in that task/step
                workflow that was important to them.
              </p>
              <p>
                We built everything from media libraries, project work order
                systems, scheduling and budgeting tools, and graphics
                containers. Our clients also wanted to share info across all the
                data containers and had to work on Mac or PC platforms. So we
                did that. We built custom tools for some very happy people.
              </p>
              <p>
                Those people spoke of their successes to their friends, family
                and colleges. The word travelled and other people contacted us
                to make similar tools for them as well… and so we did.
              </p>
              <p>
                Everyone said to us “what we are doing is very specialized and
                unique.” And it was, from the rest of the world’s workflow. But
                there was much in common ‘within’ that world. And so, a
                standardized product was born, within that world.
              </p>
              <h4 style={{ color: "black" }}>
                Our clients are on a new path now, and so are we. There is
                nothing permanent but change.
              </h4>
              <p>
                Obstacles are what you see when you lose sight of your goals.
                TDC has not lost sight of your goals.
              </p>
            </GridItem>
          </GridContainer>
        </div>
        <br />
        <SectionFeatures />
        <SectionContacts />
      </div>
      <MyFooter {...rest} />
    </div>
  );
};

export default StoryPage;
